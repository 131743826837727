.CodeMirror {
  color: #000;
  direction: ltr;
  height: 300px;
  font-family: monospace;
}

.CodeMirror-lines {
  padding: 4px 0;
}

.CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px;
}

.CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  background-color: #fff;
}

.CodeMirror-gutters {
  white-space: nowrap;
  background-color: #f7f7f7;
  border-right: 1px solid #ddd;
}

.CodeMirror-linenumber {
  text-align: right;
  color: #999;
  white-space: nowrap;
  min-width: 20px;
  padding: 0 3px 0 5px;
}

.CodeMirror-guttermarker {
  color: #000;
}

.CodeMirror-guttermarker-subtle {
  color: #999;
}

.CodeMirror-cursor {
  border-left: 1px solid #000;
  border-right: none;
  width: 0;
}

.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}

.cm-fat-cursor .CodeMirror-cursor {
  background: #7e7;
  width: auto;
  border: 0 !important;
}

.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}

.cm-fat-cursor .CodeMirror-line::selection, .cm-fat-cursor .CodeMirror-line > span::selection, .cm-fat-cursor .CodeMirror-line > span > span::selection {
  background: none;
}

.cm-fat-cursor .CodeMirror-line::-moz-selection {
  background: none;
}

.cm-fat-cursor .CodeMirror-line > span::-moz-selection {
  background: none;
}

.cm-fat-cursor .CodeMirror-line > span > span::-moz-selection {
  background: none;
}

.cm-fat-cursor {
  caret-color: #0000;
}

@keyframes blink {
  0% {
  }

  50% {
    background-color: #0000;
  }

  100% {
  }
}

.cm-tab {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  display: inline-block;
}

.CodeMirror-rulers {
  position: absolute;
  inset: -50px 0 0;
  overflow: hidden;
}

.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  position: absolute;
  top: 0;
  bottom: 0;
}

.cm-s-default .cm-header {
  color: #00f;
}

.cm-s-default .cm-quote {
  color: #090;
}

.cm-negative {
  color: #d44;
}

.cm-positive {
  color: #292;
}

.cm-header, .cm-strong {
  font-weight: bold;
}

.cm-em {
  font-style: italic;
}

.cm-link {
  text-decoration: underline;
}

.cm-strikethrough {
  text-decoration: line-through;
}

.cm-s-default .cm-keyword {
  color: #708;
}

.cm-s-default .cm-atom {
  color: #219;
}

.cm-s-default .cm-number {
  color: #164;
}

.cm-s-default .cm-def {
  color: #00f;
}

.cm-s-default .cm-variable-2 {
  color: #05a;
}

.cm-s-default .cm-variable-3, .cm-s-default .cm-type {
  color: #085;
}

.cm-s-default .cm-comment {
  color: #a50;
}

.cm-s-default .cm-string {
  color: #a11;
}

.cm-s-default .cm-string-2 {
  color: #f50;
}

.cm-s-default .cm-meta, .cm-s-default .cm-qualifier {
  color: #555;
}

.cm-s-default .cm-builtin {
  color: #30a;
}

.cm-s-default .cm-bracket {
  color: #997;
}

.cm-s-default .cm-tag {
  color: #170;
}

.cm-s-default .cm-attribute {
  color: #00c;
}

.cm-s-default .cm-hr {
  color: #999;
}

.cm-s-default .cm-link {
  color: #00c;
}

.cm-s-default .cm-error, .cm-invalidchar {
  color: red;
}

.CodeMirror-composing {
  border-bottom: 2px solid;
}

div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0b0;
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #a22;
}

.CodeMirror-matchingtag {
  background: #ff96004d;
}

.CodeMirror-activeline-background {
  background: #e8f2ff;
}

.CodeMirror {
  background: #fff;
  position: relative;
  overflow: hidden;
}

.CodeMirror-scroll {
  z-index: 0;
  outline: none;
  height: 100%;
  margin-bottom: -50px;
  margin-right: -50px;
  padding-bottom: 50px;
  position: relative;
  overflow: scroll !important;
}

.CodeMirror-sizer {
  border-right: 50px solid #0000;
  position: relative;
}

.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  z-index: 6;
  outline: none;
  display: none;
  position: absolute;
}

.CodeMirror-vscrollbar {
  top: 0;
  right: 0;
  overflow: hidden scroll;
}

.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow: scroll hidden;
}

.CodeMirror-scrollbar-filler {
  bottom: 0;
  right: 0;
}

.CodeMirror-gutter-filler {
  bottom: 0;
  left: 0;
}

.CodeMirror-gutters {
  z-index: 3;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.CodeMirror-gutter {
  white-space: normal;
  vertical-align: top;
  height: 100%;
  margin-bottom: -50px;
  display: inline-block;
}

.CodeMirror-gutter-wrapper {
  z-index: 4;
  position: absolute;
  background: none !important;
  border: none !important;
}

.CodeMirror-gutter-background {
  z-index: 4;
  position: absolute;
  top: 0;
  bottom: 0;
}

.CodeMirror-gutter-elt {
  cursor: default;
  z-index: 4;
  position: absolute;
}

.CodeMirror-gutter-wrapper ::selection {
  background-color: #0000;
}

.CodeMirror-lines {
  cursor: text;
  min-height: 1px;
}

.CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
  font-family: inherit;
  font-size: inherit;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual;
  background: none;
  border-width: 0;
  border-radius: 0;
  margin: 0;
  position: relative;
  overflow: visible;
}

.CodeMirror-wrap pre.CodeMirror-line, .CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  z-index: 0;
  position: absolute;
  inset: 0;
}

.CodeMirror-linewidget {
  z-index: 2;
  padding: .1px;
  position: relative;
}

.CodeMirror-rtl pre {
  direction: rtl;
}

.CodeMirror-code {
  outline: none;
}

.CodeMirror-scroll, .CodeMirror-sizer, .CodeMirror-gutter, .CodeMirror-gutters, .CodeMirror-linenumber {
  box-sizing: content-box;
}

.CodeMirror-measure {
  visibility: hidden;
  width: 100%;
  height: 0;
  position: absolute;
  overflow: hidden;
}

.CodeMirror-cursor {
  pointer-events: none;
  position: absolute;
}

.CodeMirror-measure pre {
  position: static;
}

div.CodeMirror-cursors {
  visibility: hidden;
  z-index: 3;
  position: relative;
}

div.CodeMirror-dragcursors, .CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected {
  background: #d9d9d9;
}

.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0;
}

.CodeMirror-crosshair {
  cursor: crosshair;
}

.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection {
  background: #d7d4f0;
}

.CodeMirror-line::-moz-selection {
  background: #d7d4f0;
}

.CodeMirror-line > span::-moz-selection {
  background: #d7d4f0;
}

.CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0;
}

.cm-searching {
  background-color: #ff06;
}

.cm-force-border {
  padding-right: .1px;
}

@media print {
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}

.cm-tab-wrap-hack:after {
  content: "";
}

span.CodeMirror-selectedtext {
  background: none;
}
/*# sourceMappingURL=index.51b1a974.css.map */
